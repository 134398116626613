<script>
  import {
    CursorArrowRays,
    Pencil,
    Trash,
    ChatBubbleBottomCenterText,
    Swatch,
    Photo,
    RectangleGroup,
    ArrowDownTray,
  } from "svelte-hero-icons";
  import IconButton from "./IconButton.svelte";
  import Slider from "./Slider.svelte";
  import { screenStores, toolbar } from "../store";

  export let ui_code_as_xml;
  export let project_name;

  function onClickDrawMode() {
    $toolbar.draw_mode = "region";
  }

  function onClickSelectMode() {
    $toolbar.draw_mode = false;
  }

  function onClickCode() {
    $toolbar.show_code = !$toolbar.show_code;
  }

  function onClickTrash() {
    screenStores.deleteSelected();
  }

  function downloadXml() {
    const filename = project_name || "UI";
    const blob = new Blob([ui_code_as_xml], { type: "application/xml" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename}.xml`;
    a.click();
    URL.revokeObjectURL(url);
  }
</script>

<div
  class="absolute z-20 left-1/2 -translate-x-1/2 bottom-6 border
  border-gray-200 bg-white flex justify-between rounded-xl overflow-hidden p-2 space-x-3
  transition-[width] duration-300 ease-in"
  style="width: {$toolbar.show_code ? '150px' : '245px'}"
>
  {#if !$toolbar.show_code}
    <IconButton
      description="Cursor (Key: V)"
      icon={CursorArrowRays}
      selected={!$toolbar.draw_mode}
      onClick={onClickSelectMode}
    />

    <IconButton
      description="Create new region (Key: R)"
      icon={Pencil}
      selected={$toolbar.draw_mode === "region"}
      onClick={onClickDrawMode}
    />
    <IconButton
      description="Delete selected elements (Key: Del)"
      icon={Trash}
      selected={false}
      onClick={onClickTrash}
    />
    {#if false}
      <IconButton
        description="TBD"
        icon={ChatBubbleBottomCenterText}
        selected={false}
        onClick={() => {}}
      />
      <IconButton description="TBD" icon={Swatch} selected={false} onClick={() => {}} />
      <IconButton description="TBD" icon={Photo} selected={false} onClick={() => {}} />
      <IconButton description="TBD" icon={RectangleGroup} selected={false} onClick={() => {}} />
    {/if}
  {:else}
    <IconButton
      description="Download XML"
      icon={ArrowDownTray}
      selected={false}
      onClick={() => downloadXml()}
    />
  {/if}
  <div class="border-l border-gray-200 mr-2"></div>
  <Slider description="Toggle code editor" checked={$toolbar.show_code} onChange={onClickCode} />
</div>
