import L from "leaflet";

export const Map = {
  mounted() {
    L.Icon.Default.imagePath = "/images/leaflet/";
    L.Icon.Default.prototype.options.imagePath = "/images/leaflet/";

    this.map = L.map(this.el, { attributionControl: false }).setView([51.505, -0.09], 2);

    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 5,
    }).addTo(this.map);

    L.marker([51.5, -0.09]).addTo(this.map);
  },
};
