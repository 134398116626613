import uPlot from "uplot";

import { zoom } from "./plugins/zoom";
import { preset } from "./plugins/preset";
import { debug } from "./plugins/debug";
import { dataSync } from "./plugins/dataSync";
import { resize } from "./plugins/resize";

import { parseJsonOrDefault } from "../utils/parseJsonOrDefault";

function generateId(chart) {
  return chart.id + "__chart";
}

export const Chart = {
  mounted() {
    const $chart = this.el;

    const data = parseJsonOrDefault($chart.dataset.data, []);
    const labels = parseJsonOrDefault($chart.dataset.labels, []);
    const preset_id = $chart.dataset.preset;
    const unit = $chart.dataset.unit;
    const selectedIndex =
      $chart.dataset.selected !== undefined ? parseInt($chart.dataset.selected) : null;
    const title = $chart.dataset.title || "";
    const id = generateId($chart);

    this.target = $chart.dataset.target === "" ? null : $chart.dataset.target;

    const opts = {
      title,
      id,
      plugins: [
        dataSync($chart),
        resize($chart),
        preset(preset_id, {
          labels,
          data,
          unit,
          onSelectedIndex: this.onSelectedIndex.bind(this),
          selectedIndex,
        }),
        zoom(false),
        window.enable_chart_debug ? debug() : undefined,
      ].filter((plugin) => plugin),
    };

    this.uplot = new uPlot(opts, data, $chart);
  },

  onSelectedIndex(selected, timestamp, eventName = "graph_value_selected") {
    if (this.target) {
      this.pushEventTo(this.target, eventName, { selected, timestamp });
      return;
    }

    this.pushEvent(eventName, { selected, timestamp });
  },

  destroyed() {
    if (this.uplot) {
      this.uplot.destroy();
    }
  },
};
