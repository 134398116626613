<script>
  import StateEvent from "../Timeline/StateEvent.svelte";
  import { Icon, ChevronRight, ChevronDown } from "svelte-hero-icons";
  import { timeline, playback } from "../store";

  export let track;
  export let trackHeaderSize;
  export let trackEventsLeft;

  const { scaledTimelineWidth, visibleUIStateElements, uiStateSubtracks } = timeline;
  const { totalDuration } = playback;

  $: selectedElement = timeline.selectedTimelineItem;
  $: elements = visibleUIStateElements;
  $: getSubtracks = () => {
    return $uiStateSubtracks;
  };
  $: calcPxStartTime = (event) => {
    return (event.start * $scaledTimelineWidth) / $totalDuration;
  };
  $: calcPxDuration = (event) => {
    const minWidth =
      {
        state: 22,
        workflow: 22,
      }[event.type] || 0.033;
    return durationToPx(event.duration, minWidth);
  };
  $: durationToPx = (duration, minWidth) => {
    const total = Math.max($totalDuration, 0.033);
    const result = Math.max((duration * $scaledTimelineWidth) / total, minWidth);
    if (!isFinite(result)) return 0;
    return result;
  };
</script>

<div
  class="flex border-b border-gray-200 bg-white h-8"
  style="min-width: 100%; width: {$scaledTimelineWidth + trackEventsLeft + trackHeaderSize}px;"
>
  <!-- Track Header -->
  <div
    data-el-tracker-header
    class="w-36 flex-shrink-0 px-2 flex items-center justify-between sticky left-0 z-[1000] bg-white border-r border-gray-200"
  >
    <span class="font-medium">{track.name}</span>
    {#if track.hasSubtrack}
      <button
        on:click={() => timeline.toggleSubtrack(track.id)}
        class="text-gray-500 hover:text-gray-700"
      >
        <Icon src={track.showSubtrack ? ChevronDown : ChevronRight} class="w-4 h-4" />
      </button>
    {/if}
  </div>
  <!-- Track Events -->
  <div class="flex items-center relative" style="left: {trackEventsLeft}px;">
    {#each $elements as event}
      <StateEvent
        {event}
        x={calcPxStartTime(event)}
        width={calcPxDuration(event)}
        selected={$selectedElement == event}
      />
    {/each}
  </div>
</div>
{#if track.showSubtrack}
  {#each getSubtracks().entries() as [subtrackJSON, subEvents]}
    {@const subtrack = JSON.parse(subtrackJSON)}
    <div
      class="flex border-b border-gray-200 bg-white h-8"
      style="min-width: 100%; width: {$scaledTimelineWidth + trackEventsLeft + trackHeaderSize}px;"
    >
      <!-- Track Header -->
      <div
        data-el-tracker-header
        class="w-36 flex-shrink-0 px-4 flex items-center justify-between sticky left-0 z-[1000] bg-white border-r border-gray-300"
      >
        <span class="font-medium">{subtrack.name || subtrack.id}</span>
      </div>
      <!-- Track Events -->
      <div class="flex items-center relative" style="left: {trackEventsLeft}px;">
        {#each subEvents as event}
          <StateEvent
            {event}
            x={calcPxStartTime(event)}
            width={calcPxDuration(event)}
            selected={$selectedElement?.id == event.id}
          />
        {/each}
      </div>
    </div>
  {/each}
{/if}
