<script>
  import "./automator.css";

  import SequentialWorkflowDesigner from "./Internal/SequentialWorkflowDesigner.svelte";
  import { workflow, toolbar, execution, currentStep, definitions } from "./store";
  import { shouldIgnoreKeyEvent } from "../../js/utils/keyboard";

  import StepEditor from "./PropEditor/WorkflowStepEditor.svelte";
  import RootEditor from "./PropEditor/WorkflowRootEditor.svelte";
  import { generateUUID } from "$utils/uuid";
  import { signalStore } from "$utils/signal-store";
  import { createStatusBadgesExtension } from "./Workflow/Badges/statusExtension";
  import { createCommentExtension } from "./Workflow/Badges/commentExtension";
  import { createBlockInclusivityExtension } from "./Workflow/Block/extension";

  const layoutUpdate = signalStore();

  $: isReadonly = workflow.isReadonly;
  $: toolbox = definitions.toolbox;
  $: isToolboxCollapsed = !$toolbar.show_toolbox;
  $: $toolbar, layoutUpdate.trigger();

  const steps = {
    isAutoSelectDisabled: false,
    iconUrlProvider: definitions.iconUrlProvider,
  };

  const validator = {
    step: (step) => execution.stepValidator(step.id),
    status: (step) => execution.stepStatus(step.id),
    hasComments: (step) => workflow.stepHasComments(step),
  };

  function onDefinitionChanged({ detail }) {
    workflow.internalUpdate(detail);
  }

  function onSelectedStepIdChanged(event) {
    const { detail } = event;
    execution.setCurrentStep(detail.stepId);
    document.querySelector(`.sqd-toolbox-filter`)?.blur();
  }

  function onIsToolboxCollapsedChanged({ detail }) {
    toolbar.update((state) => {
      return { ...state, show_toolbox: !detail.isCollapsed };
    });
  }

  const uidGenerator = () => generateUUID("w");

  const extensions = [
    {
      badges: [
        createStatusBadgesExtension(),
        createCommentExtension(),
        createBlockInclusivityExtension(),
      ],
    },
  ];

  const keyboardConfiguration = {
    canHandleKey(_action, event) {
      return !shouldIgnoreKeyEvent(event);
    },
  };
</script>

<SequentialWorkflowDesigner
  theme="light"
  definition={$workflow}
  on:definitionChanged={onDefinitionChanged}
  undoStackSize={20}
  {steps}
  {validator}
  toolbox={$toolbar.show_toolbox ? $toolbox : false}
  {uidGenerator}
  {extensions}
  selectedStepId={$currentStep}
  stepEditor={StepEditor}
  rootEditor={RootEditor}
  on:selectedStepIdChanged={onSelectedStepIdChanged}
  {isToolboxCollapsed}
  isEditorCollapsed={!$toolbar.show_prop_pane}
  on:isToolboxCollapsedChanged={onIsToolboxCollapsedChanged}
  isReadonly={$isReadonly}
  badgeStore={execution.badges}
  layoutStore={layoutUpdate}
  keyboard={keyboardConfiguration}
/>
