import { get } from "$utils/shim";
import { createMockLive } from "../MockLiveSocket";
import Automator from "../../Automator.svelte";

export function generateValidationIssues(content) {
  const events = [];
  let id = 1;

  content.sequence.forEach((step) => {
    events.push({
      data: {
        step_id: step.id,
        isValid: false,
        invalidInputs: {
          name: ["Can not be empty"],
          coldStart: ["Must be checked for App Netflix"],
        },
      },
      id: id++,
      timestamp: Date.now(),
      type: "validation",
    });
  });

  return events;
}

// TODO: Add pts
export function generateEvents(content) {
  const events = [];
  let id = 1;

  events.push({
    data: {},
    id: id++,
    timestamp: Date.now(),
    type: "automation_started",
  });

  content.sequence.forEach((step) => {
    let start_time = Date.now();

    events.push({
      data: {
        step_id: step.id,
      },
      id: id++,
      timestamp: start_time,
      type: "workflow_begin_step",
    });

    Object.entries(step.properties).forEach(([key, value]) => {
      events.push({
        data: {
          step_id: step.id,
          key,
          value,
        },
        id: id++,
        timestamp: Date.now(),
        type: "workflow_log_input",
      });
    });

    events.push({
      data: {
        step_id: step.id,
        key: "result",
        value: "ok",
      },
      id: id++,
      timestamp: Date.now(),
      type: "workflow_log_output",
    });

    const isFailure = step.type === "close";
    events.push({
      data: {
        step_id: step.id,
        result: "ok",
      },
      id: id++,
      timestamp: Date.now(),
      type: isFailure ? "workflow_fail_step" : "workflow_end_step",
    });
  });

  events.push({
    data: {
      reason: "Script executed successfully",
      status: "success",
    },
    id: id++,
    timestamp: Date.now(),
    type: "automation_ended",
  });

  return events;
}

export async function simulateWorkflow(events, emitCallback, delay = 100) {
  return new Promise((resolve) => {
    let eventIndex = 0;

    function replayNextEvent() {
      if (eventIndex < events.length) {
        const event = events[eventIndex];
        emitCallback(event);
        eventIndex++;
        setTimeout(replayNextEvent, delay);
      } else {
        resolve();
      }
    }

    replayNextEvent();
  });
}

const mock = createMockLive({
  initialState() {
    return {
      job: {},
      active_workflow_id: "",
      project_id: "",
      project_name: "",
      user_nickname: "",
      code: "",
      ui_assets: [],
      toolbox: [],
      root_definition: [],
      tracing: false,
      debugging: false,
      user_roles: ["root"],
      workflow: { properties: {}, sequence: [] },
    };
  },

  handleEvent(name, payload, ctx) {
    switch (name) {
      case "automator_changed":
        this.handleAutomatorChanged(payload, ctx);
        break;
      case "request_targets":
        this.handleRequestTargets(payload, ctx);
        break;
      case "debug":
      case "trace":
        this.handleRunScript(payload, name, ctx);
        break;
      case "execute_all":
        break;
    }
  },

  handleAutomatorChanged(payload, { updateProps }) {
    updateProps({ workflow: payload.workflow });
  },

  handleRequestTargets(_payload, { replyFn }) {
    setTimeout(() => {
      const apps = [
        { id: 1, name: "App A" },
        { id: 2, name: "App B" },
        { id: 3, name: "App C" },
      ];
      const devices = [
        { id: 1, make: "LG", model: "C1", logo: "https://placehold.co/32x32" },
        { id: 2, make: "LG", model: "C2", logo: "https://placehold.co/32x32" },
        { id: 3, make: "LG", model: "C3", logo: "https://placehold.co/32x32" },
      ];
      replyFn({ apps, devices, count: 10 });
    }, 100);
  },

  async handleRunScript(_payload, type, { triggerEvent, updateProps, props }) {
    updateProps(type === "debug" ? { debugging: true } : { tracing: true });
    const events = generateEvents(get(props).workflow);
    await simulateWorkflow(events, (event) => triggerEvent("workflow", event));
    updateProps(type === "debug" ? { debugging: false } : { tracing: false });
  },
});

export default { mock, Component: Automator };
