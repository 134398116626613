<script>
  import { workflow, definitions, execution, toolbar } from "./store";
  import { getInputComponent } from "./PropEditor/inputs";
  import DropdownMenu from "$lib/Elements/DropdownMenu.svelte";
  import TextInput from "$lib/Inputs/TextInput.svelte";
  import NumericInput from "$lib/Inputs/NumericInput.svelte";
  import CheckboxInput from "$lib/Inputs/CheckboxInput.svelte";

  function flattenWorkflow(sequence, depth = 0) {
    return sequence.flatMap((step) => {
      const flatSteps = [{ ...step, depth }];

      if (step.branches) {
        Object.values(step.branches).forEach((branchSequence) => {
          flatSteps.push(...flattenWorkflow(branchSequence, depth + 1));
        });
      }

      if (step.sequence) {
        flatSteps.push(...flattenWorkflow(step.sequence, depth + 1));
      }

      return flatSteps;
    });
  }

  $: allSteps = flattenWorkflow($workflow?.sequence || []);

  // Group steps by type
  $: groupedSteps = allSteps.reduce((acc, step) => {
    const type = step.type;
    if (!acc[type]) acc[type] = [];
    acc[type].push(step);
    return acc;
  }, {});

  // Sort groups by type
  $: sortedGroups = Object.entries(groupedSteps).sort(([a], [b]) => a.localeCompare(b));

  function getPropsForType(type) {
    const def = definitions.getDefinition(type);
    return Object.keys(def?.inputs || {})
      .filter((name) => name !== "branches")
      .sort();
  }

  function getStepState(stepId) {
    return (
      $execution[stepId] || {
        inputs: {},
        outputs: {},
        status: "",
        validations: {},
        duration: 0,
        progress: 0,
        errors: [],
      }
    );
  }

  function selectStep(stepId) {
    execution.setCurrentStep(stepId);
    toolbar.update((state) => ({ ...state, show_table: false }));
  }

  function shouldUseSimpleInput(propertyType) {
    if (!(propertyType instanceof Object)) {
      return ["string", "number", "boolean"].includes(propertyType);
    }
    return ["string", "number", "boolean"].includes(propertyType.type);
  }

  function getSimpleComponent(propertyType) {
    const type = propertyType instanceof Object ? propertyType.type : propertyType;
    switch (type) {
      case "string":
        return TextInput;
      case "number":
        return NumericInput;
      case "boolean":
        return CheckboxInput;
      default:
        return null;
    }
  }

  function formatValue(value) {
    if (value === undefined || value === null) return "*unset*";
    if (Array.isArray(value)) {
      if (value.length === 0) return "*unset*";
      return value.join(", ");
    }
    if (value instanceof Object) return JSON.stringify(value);
    return String(value);
  }
</script>

<div class="h-full p-2 overflow-auto space-y-6">
  {#each sortedGroups as [groupType, steps]}
    {@const typeProps = getPropsForType(groupType)}
    <div class="bg-white rounded shadow">
      <!-- Group Header -->
      <div class="bg-gray-100 px-2 py-1.5 text-sm font-mono font-semibold text-gray-700 rounded-t">
        {groupType} ({steps.length})
      </div>

      <table class="w-full table-fixed divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="w-48 px-2 py-2 text-left text-xs font-mono text-gray-500">Step</th>
            {#each typeProps as propName}
              <th class="w-48 px-2 py-2 text-left text-xs font-mono text-gray-500">
                <span class="bg-blue-100 text-blue-800 text-xs px-1 rounded">{propName}</span>
              </th>
            {/each}
            <th class="px-2 py-2 text-left text-xs font-mono text-gray-500">Status</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          {#each steps as step}
            {@const stepDef = definitions.getDefinition(step.type)}
            {@const state = getStepState(step.id)}
            <tr class="hover:bg-gray-50">
              <td class="px-2 py-1 whitespace-normal text-xs font-mono">
                <button
                  class="text-blue-600 hover:text-blue-800 text-left break-words"
                  on:click={() => selectStep(step.id)}
                >
                  {"→".repeat(step.depth)}
                  {step.name || stepDef?.name || step.type}
                </button>
              </td>

              {#each typeProps as propName}
                <td class="px-2 py-1 whitespace-nowrap text-xs">
                  {#if stepDef?.inputs?.[propName]}
                    {@const propertyType = stepDef.inputs[propName]}
                    <!-- <pre>{JSON.stringify(propertyType, null, 2)}</pre> -->
                    {#if shouldUseSimpleInput(propertyType)}
                      {@const SimpleComponent = getSimpleComponent(propertyType)}
                      {#if step.properties?.[propName] !== undefined}
                        <SimpleComponent
                          value={step.properties?.[propName] ?? ""}
                          class="text-xs"
                          variant={"narrow"}
                          label={propertyType["type"] == "boolean" ? "" : "v ="}
                          optional={propertyType["optional"] === true}
                          on:input={(e) => {
                            if (!step.properties) step.properties = {};
                            step.properties[propName] = e.detail;
                          }}
                        />
                      {:else}
                        <div class="text-xs">*unset*</div>
                      {/if}
                    {:else}
                      <DropdownMenu
                        buttonText={formatValue(step.properties?.[propName])}
                        buttonClass="w-full text-xs"
                      >
                        <svelte:fragment slot="content" let:close>
                          {@const [component, props] = getInputComponent(
                            propName,
                            propertyType,
                            step,
                          )}
                          <svelte:component
                            this={component}
                            value={step.properties?.[propName] ?? ""}
                            {...props}
                            on:input={(e) => {
                              if (!step.properties) step.properties = {};
                              step.properties[propName] = e.detail;
                              close();
                            }}
                          />
                        </svelte:fragment>
                      </DropdownMenu>
                    {/if}
                  {/if}
                </td>
              {/each}

              <td class="px-2 py-1 whitespace-nowrap text-xs">
                <div class="flex items-center space-x-2">
                  <span
                    class={`px-1.5 py-0.5 inline-flex text-xs leading-4 font-mono rounded
                      ${
                        state.status === "succeeded"
                          ? "bg-green-100 text-green-800"
                          : state.status === "failed"
                            ? "bg-red-100 text-red-800"
                            : state.status === "in_progress"
                              ? "bg-blue-100 text-blue-800"
                              : "bg-gray-100 text-gray-800"
                      }`}
                  >
                    {state.status || "pending"}
                  </span>

                  {#if Object.keys(state.outputs || {}).length > 0}
                    <DropdownMenu buttonText="Outputs" buttonClass="text-xs">
                      <svelte:fragment slot="content">
                        {#each Object.entries(state.outputs) as [key, value]}
                          <div class="mb-1">
                            <span
                              class="font-mono bg-purple-100 text-purple-800 text-xs px-1 rounded"
                              >out</span
                            >
                            <span class="font-mono ml-1">{key}:</span>
                            <span class="ml-1 font-mono text-gray-600">{formatValue(value)}</span>
                          </div>
                        {/each}
                      </svelte:fragment>
                    </DropdownMenu>
                  {/if}

                  {#if state.errors?.length > 0}
                    <div class="text-xs text-red-600 font-mono">
                      {#each state.errors as error}
                        <div>{error}</div>
                      {/each}
                    </div>
                  {/if}
                </div>
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  {/each}
</div>
