<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, PaperAirplane } from "svelte-hero-icons";
  import { execution, features } from "../store";
  import DropdownMenu from "$lib/Elements/DropdownMenu.svelte";
  import SliderInput from "$lib/Inputs/SliderInput.svelte";
  import Button from "$lib/Elements/Button.svelte";
  import TextInput from "$lib/Inputs/TextInput.svelte";
  import CheckboxInput from "$lib/Inputs/CheckboxInput.svelte";
  import DurationInput from "$lib/Inputs/DurationInput.svelte";
  import DeviceSelector from "$lib/Inputs/DeviceSelector.svelte";

  export let apps = [];
  export let devices = [];

  let userTag = null;
  let selectedDevices = [];

  $: developerMode = features.developerMode;

  const dispatch = createEventDispatcher();

  let selectedMultiplier = 1;
  let godMode = false;
  let requestTimeout = null;

  function handleMultiplierChange(event) {
    selectedMultiplier = event.detail;
  }

  function handleTimeoutChange(event) {
    requestTimeout = event.detail;
  }

  function executeAll() {
    execution.triggerExecuteAll({
      soak: selectedMultiplier,
      godMode,
      userTag,
      deviceFilter: selectedDevices,
      requestTimeout,
    });
  }

  function onOpen() {
    dispatch("open", {});
  }
</script>

<DropdownMenu buttonText="Execute All" {onOpen}>
  <div slot="prefix" class="flex items-center">
    <Icon src={PaperAirplane} class="w-4 h-4 mr-1" />
  </div>

  <div slot="content" let:close>
    <h3 class="text-md font-semibold text-gray-800 mb-2">Execute All Targets</h3>
    <p class="text-sm text-gray-600 mb-4">
      Are you sure you want to execute <span class="font-bold">
        {apps.length * selectedDevices.length * selectedMultiplier}
      </span>
      runs? <span class="text-xs text-gray-500">Edit in settings tab</span>
    </p>

    {#if devices}
      <div class="mb-4">
        <DeviceSelector bind:value={selectedDevices} {devices} label="Select Devices" optional />
      </div>
    {/if}

    {#if $developerMode}
      <p class="text-sm mb-2 text-red-800">Developer options:</p>

      <div class="mb-4">
        <SliderInput
          bind:value={selectedMultiplier}
          min={1}
          max={500}
          step={1}
          label="Execution Multiplier"
          on:input={handleMultiplierChange}
          let:value
        >
          {value}x
        </SliderInput>
      </div>

      <div class="mb-4 text-xs">
        <CheckboxInput
          value={godMode}
          on:input={(e) => (godMode = e.detail)}
          label="Run in God Mode"
        />
      </div>

      <div class="mb-4 text-xs">
        <TextInput variant="wide" bind:value={userTag} label="User Metric Tag" optional />
      </div>

      <div class="mb-4 text-xs">
        <DurationInput
          value={requestTimeout}
          on:input={handleTimeoutChange}
          label="Request Timeout"
          variant="ultrawide"
          defaultUnit="minutes"
          optional
        />
      </div>
    {/if}

    <div class="flex justify-end space-x-2">
      <Button variant="secondary" on:click={close}>Cancel</Button>

      <Button
        icon={PaperAirplane}
        on:click={() => {
          executeAll();
          close();
        }}
      >
        Execute
      </Button>
    </div>
  </div>
</DropdownMenu>
