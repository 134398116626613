<script>
  import { createEventDispatcher } from "svelte";
  import { Icon, Tv, XMark } from "svelte-hero-icons";

  export let value = [];
  export let devices = [];
  export let isReadonly = false;
  export let label = "";
  export let optional = false;

  const dispatch = createEventDispatcher();

  function toggleDevice(device) {
    if (isReadonly) return;

    const newValue = value?.includes(device.value)
      ? value.filter((id) => id !== device.value)
      : [...(value || []), device.value];

    value = newValue;
    dispatch("input", newValue);
  }

  function clearSelection() {
    if (isReadonly) return;
    value = [];
    dispatch("input", value);
  }

  function selectAll() {
    if (isReadonly) return;
    value = devices.map((device) => device.value);
    dispatch("input", value);
  }
</script>

<!-- svelte-ignore a11y-label-has-associated-control -->
{#if label}<label class="block text-gray-500 text-xs mb-2">{label}</label>{/if}

<div class="border rounded-lg p-2 max-w-md" class:opacity-50={isReadonly}>
  <div class="flex justify-between items-center mb-1">
    <h3 class="font-semibold text-xs">Devices</h3>
    <div class="flex items-center space-x-2">
      <button
        class="text-xs text-gray-500 hover:text-gray-700 focus:outline-none"
        on:click={selectAll}
        disabled={isReadonly}
      >
        Select All
      </button>
      {#if optional}
        <button
          class="text-xs text-gray-500 hover:text-gray-700 focus:outline-none"
          on:click={clearSelection}
          disabled={isReadonly}
        >
          <Icon src={XMark} class="w-4 h-4" />
        </button>
      {/if}
      <slot name="dynamic" />
    </div>
  </div>
  <ul class="space-y-0.5 max-h-48 overflow-y-auto">
    {#each devices as device (device.value)}
      <li>
        <label
          class="w-full flex items-center px-1.5 py-0.5 rounded-sm hover:bg-gray-100 focus:outline-none focus:bg-gray-100 text-xs cursor-pointer"
        >
          <input
            type="checkbox"
            checked={value?.includes(device.value)}
            class="mr-1.5 h-3 w-3"
            disabled={isReadonly}
            on:change={() => toggleDevice(device)}
          />
          {#if device.image}
            <img
              src={device.image}
              alt={device.label}
              class="w-3 h-3 object-contain mr-1 flex-shrink-0"
            />
          {:else}
            <Icon src={Tv} class="w-3 h-3 text-gray-600 mr-1 flex-shrink-0" />
          {/if}
          <span class="truncate">{device.label || "Unnamed Device"}</span>
        </label>
      </li>
    {/each}
  </ul>
</div>
