<script>
  import {
    CpuChip,
    Beaker,
    CursorArrowRays,
    ListBullet,
    ChartBarSquare,
    Sun,
    EyeDropper,
    Newspaper,
    Photo,
    PlayCircle,
    Film,
  } from "svelte-hero-icons";

  import Collapsible from "./Elements/Collapsible.svelte";
  import ActiveElement from "./Elements/ActiveElement.svelte";
  import MetricsTable from "./Elements/MetricsTable.svelte";
  import SystemStats from "./SystemStats.svelte";
  import GenericEventInspectPanel from "./InspectPanels/GenericEventInspectPanel.svelte";
  import ColorEventInspectPanel from "./InspectPanels/ColorEventInspectPanel.svelte";
  import ImageEventInspectPanel from "./InspectPanels/ImageEventInspectPanel.svelte";
  import TextEventInspectPanel from "./InspectPanels/TextEventInspectPanel.svelte";
  import PlaybackProgressBarInspectPanel from "./InspectPanels/PlaybackProgressBarInspectPanel.svelte";
  import MotionPreview from "./InspectPanels/MotionPreview.svelte";

  import { timeline, currentStep, features, playback, toolbar } from "./store";
  import DataGrid from "./DataGrid/DataGrid.svelte";
  import Debug from "$lib/Elements/Debug.svelte";

  $: developerMode = features.developerMode;
  $: uniqueMatchers = timeline.uniqueMatchers;
  $: visibleMetricsEvents = timeline.visibleMetricsEvents;
  $: activeTimelineEvents = timeline.activeTimelineEvents;
  $: currentTime = playback.currentTime;
  $: selectedTimelineItem = timeline.selectedTimelineItem;
  // $: selectedEventBasedOnTime = timeline.selectedEventBasedOnTime;

  const matcherMap = {
    brightness: { component: GenericEventInspectPanel, icon: Sun },
    color: { component: ColorEventInspectPanel, icon: EyeDropper },
    text: { component: TextEventInspectPanel, icon: Newspaper },
    image: { component: ImageEventInspectPanel, icon: Photo },
    playback_progress_bar: { component: PlaybackProgressBarInspectPanel, icon: PlayCircle },
  };

  const ignoredEventKeys = ["accumulatedMotion"];

  function handleToggle(event) {
    const { id, active } = event.detail;
    timeline.toggleIsSelected(id, active);
  }

  function formatValue(value) {
    if (typeof value === "object") {
      return JSON.stringify(value);
    }

    return value;
  }
</script>

<div class="text-xs h-full flex flex-col overflow-auto -mt-2">
  {#if $developerMode}
    <Collapsible title="System Stats" icon={CpuChip} isOpen={false}>
      <SystemStats />
    </Collapsible>
  {/if}

  <Collapsible title="Active Matchers" icon={ListBullet} isOpen={false}>
    <div class="p-2 space-y-1">
      {#each $uniqueMatchers as element (element.ui_id)}
        <ActiveElement {element} showInOverlay={element.showInOverlay} on:toggle={handleToggle} />
      {/each}
    </div>
  </Collapsible>

  <Collapsible title="Metrics" icon={ChartBarSquare}>
    <div class="pl-1">
      <MetricsTable metrics={visibleMetricsEvents} />
    </div>
  </Collapsible>

  {#if $currentStep && $currentStep.subtype === "detect_playback"}
    <Collapsible title="Motion" icon={Film}>
      <MotionPreview step={$currentStep} time={$currentTime} />
    </Collapsible>
  {/if}

  <!--
  {#if $selectedEventBasedOnTime}
    {@const inspect = matcherMap[$selectedEventBasedOnTime.ui_type] || {
      component: GenericEventInspectPanel,
      icon: Beaker,
    }}
    <Collapsible title={$selectedEventBasedOnTime.name} icon={inspect.icon}>
      <svelte:component this={inspect.component} event={$selectedEventBasedOnTime} />
    </Collapsible>
  {/if}
  -->

  {#each $activeTimelineEvents as matcherEvent (matcherEvent.id)}
    {@const inspect = matcherMap[matcherEvent.ui_type] || {
      component: GenericEventInspectPanel,
      icon: Beaker,
    }}
    <Collapsible title={matcherEvent.name} icon={inspect.icon}>
      <svelte:component this={inspect.component} event={matcherEvent} />
    </Collapsible>
  {/each}

  {#if $selectedTimelineItem}
    <Collapsible title={"Selected Event"} icon={CursorArrowRays}>
      <DataGrid
        data={Object.entries($selectedTimelineItem).reduce((acc, [key, value]) => {
          if (ignoredEventKeys.includes(key)) return acc;

          acc.push({ id: key, label: key, value: formatValue(value), type: "text" });
          return acc;
        }, [])}
      />
    </Collapsible>
  {/if}

  {#if $toolbar.show_debug}
    <Debug activeTimelineEvents={$activeTimelineEvents} />
    <Debug selectedTimelineItem={$selectedTimelineItem} />
  {/if}
</div>
