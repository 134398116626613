<script>
  import { Icon, DocumentArrowDown, Camera } from "svelte-hero-icons";
  import Modal from "$lib/Elements/Modal.svelte";
  import CopyText from "$lib/Elements/CopyText.svelte";
  import CheckboxInput from "$lib/Inputs/CheckboxInput.svelte";
  import TextInput from "$lib/Inputs/TextInput.svelte";
  import { playback } from "../store";

  let isOpen = false;
  let startTime = null;
  let endTime = null;
  let useReEncoding = false;
  let output = "recording.mp4";
  let isScreenshot = false;

  $: src = playback.sourceUrl;
  $: currentTime = playback.currentTime;

  function formatTimestamp(time) {
    if (!time) return null;

    if (typeof time === "string" && time.includes(":")) {
      const [minutes, seconds] = time.split(":").map(Number);
      if (!isNaN(minutes) && !isNaN(seconds) && seconds < 60) {
        return time;
      }
    }

    return null;
  }

  function secondsToMMSS(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  $: command = isScreenshot
    ? [
        `ffmpeg`,
        `-ss ${formatTimestamp(startTime) || "00:00"}`,
        `-i ${$src}`,
        `-vframes 1`,
        `-q:v 2`,
        `screenshot.jpg`,
      ].join(" ")
    : [
        `ffmpeg -i ${$src}`,
        startTime != null && `-ss ${formatTimestamp(startTime)}`,
        endTime != null && `-to ${formatTimestamp(endTime)}`,
        useReEncoding ? `-vf "fps=30,scale=1920:1080" -c:a copy` : `-c copy`,
        output,
      ]
        .filter(Boolean)
        .join(" ");

  function toggle() {
    isOpen = !isOpen;
    if (isOpen) {
      startTime = secondsToMMSS($currentTime);
    }
  }

  function toggleMode() {
    isScreenshot = !isScreenshot;
    // Reset some values when switching modes
    startTime = secondsToMMSS($currentTime);
    endTime = null;
  }
</script>

<div class="flex">
  <div class="flex flex-grow justify-start">
    <div class="inline-flex text-xs pl-4 text-gray-400">
      Note: Text overlays are delayed by 3 frames.
    </div>
  </div>

  <div class="flex flex-grow justify-end">
    <button
      on:click={toggle}
      class="inline-flex text-xs pr-4 mr-1 text-blue-500 hover:text-blue-600 disabled:text-gray-400 disabled:cursor-not-allowed transition-colors duration-200"
      disabled={!$src}
    >
      <Icon src={DocumentArrowDown} class="w-4 h-4 mr-1" />
      Download Instructions
    </button>
  </div>
</div>

<Modal
  open={isOpen}
  title={isScreenshot ? "Export Screenshot" : "Download Recording"}
  on:close={toggle}
>
  <div class="text-sm">
    <div class="flex justify-end mb-4">
      <button on:click={toggleMode} class="text-blue-500 hover:text-blue-600 flex items-center">
        <Icon src={isScreenshot ? DocumentArrowDown : Camera} class="w-4 h-4 mr-1" />
        Switch to {isScreenshot ? "video download" : "screenshot"}
      </button>
    </div>

    <p>
      To {isScreenshot ? "export a screenshot" : "download this recording"}, follow these steps:
    </p>
    <ol class="list-decimal list-inside mt-2">
      <li>
        Download and install FFmpeg from the official website:
        <a
          href="https://ffmpeg.org/download.html"
          target="_blank"
          class="text-blue-500 hover:underline"
        >
          https://ffmpeg.org/download.html
        </a>
      </li>
      <li class="mt-2">Open a terminal and run the following command:</li>
    </ol>
    <div class="relative mb-4">
      <pre
        class="font-mono text-xs bg-gray-100 border rounded border-gray-300 p-4 mt-4 pr-6 whitespace-pre-wrap">{command}</pre>
      <div class="absolute top-4 right-4">
        <CopyText text={command} showTooltip={true} />
      </div>
    </div>
    <div class="mt-2 w-full grid grid-cols-3 gap-4">
      <TextInput
        variant="ultrawide"
        bind:value={startTime}
        label={isScreenshot ? "Timestamp (MM:SS)" : "Start time (MM:SS)"}
        optional={!isScreenshot}
        placeholder="00:00"
      />
      {#if !isScreenshot}
        <TextInput
          variant="ultrawide"
          bind:value={endTime}
          label="End time (MM:SS)"
          optional={true}
          placeholder="00:00"
        />
        <CheckboxInput bind:value={useReEncoding} label="Re-encode to 30 FPS / 1080p" />
      {/if}
    </div>
    {#if !isScreenshot}
      <p class="mt-2">The recording will be saved as <code>{output}</code>.</p>
    {:else}
      <p class="mt-2">The screenshot will be saved as <code>screenshot.jpg</code>.</p>
    {/if}
  </div>
</Modal>
