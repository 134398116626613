export const ScrollLogs = {
  mounted() {
    this.hasUserScrolledUp = false;

    this.scrollBottomButton = Array.from(this.el.getElementsByTagName("button"))[0];

    this.detectUserScroll = this.detectUserScroll.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);

    this.el.addEventListener("scroll", this.detectUserScroll);
    this.el.addEventListener("sauron:scroll-bottom", this.scrollToBottom);
  },

  updated() {
    this.handleScroll(this.el);
  },

  detectUserScroll() {
    let scrollHeight = this.el.scrollHeight;
    let scrollTop = this.el.scrollTop;
    let clientHeight = this.el.clientHeight;

    // If the user is not near the bottom, mark them as having scrolled up
    if (scrollTop + clientHeight < scrollHeight - 10) {
      this.isUserScrolledUp = true;
      this.scrollBottomButton.classList.remove("hidden");
    } else {
      this.isUserScrolledUp = false;
      this.scrollBottomButton.classList.add("hidden");
    }
  },

  handleScroll() {
    let scrollHeight = this.el.scrollHeight;
    let scrollTop = this.el.scrollTop;
    let clientHeight = this.el.clientHeight;

    // Scroll to bottom if we're near the bottom or the user hasn't scrolled up
    if (scrollTop + clientHeight >= scrollHeight - 10 || !this.isUserScrolledUp) {
      this.el.scrollTop = this.el.scrollHeight;
    }
  },

  scrollToBottom() {
    this.el.scrollTop = this.el.scrollHeight;
  },

  destroyed() {
    this.el.removeEventListener("scroll", this.detectUserScroll);
    this.el.removeEventListener("sauron:scroll-bottom", this.scrollToBottom);
  },
};
