<script>
  import { slide } from "svelte/transition";
  import { Icon, ChevronDown, ChevronRight } from "svelte-hero-icons";

  export let title;
  export let icon = null;
  export let isOpen = true;
  export let variant = "default";

  function toggle() {
    isOpen = !isOpen;
  }
</script>

<div class={variant === "default" ? " border-b border-gray-200" : ""}>
  <button
    on:click={toggle}
    class="w-full px-3 py-2 flex items-center justify-between text-left transition-colors duration-200"
  >
    <div class="flex items-center w-full">
      {#if icon}
        <Icon src={icon} class="w-4 h-4 mr-1 flex-shrink-0" />
      {/if}
      {#if title}
        <span class="font-semibold">{title}</span>
      {/if}
      <slot name="title"></slot>
    </div>
    <Icon src={isOpen ? ChevronDown : ChevronRight} class="w-4 h-4" />
  </button>

  {#if isOpen}
    <div transition:slide={{ duration: 300 }}>
      <slot></slot>
    </div>
  {/if}
</div>
