<script>
  import {
    Icon,
    CodeBracket,
    PlayCircle,
    PlusCircle,
    ExclamationCircle,
    BugAnt,
    QuestionMarkCircle,
    QueueList,
    CheckCircle,
    PauseCircle,
    TableCells,
    Document,
    DocumentCheck,
    LockOpen,
    LockClosed,
  } from "svelte-hero-icons";
  import {
    toolbar,
    workflow,
    currentStep,
    execution,
    liveStore,
    filetree,
    features,
    jobs,
  } from "./store";
  import {
    generateEvents,
    generateValidationIssues,
    simulateWorkflow,
  } from "$lib/Mock/mocks/automator";
  import { get } from "$lib/utils/shim";

  import { tooltip } from "$lib/utils/tooltip";
  import { persisted } from "$lib/utils/persisted-stores";
  import HelpModal from "$lib/Elements/HelpModal.svelte";
  import TraceDebug from "./Menubar/TraceDebug.svelte";
  import Execute from "./Menubar/Execute.svelte";

  export let hasActiveWorkflow = false;

  $: isValid = workflow.isValid;
  $: isReadonly = workflow.isReadonly;
  $: developerMode = features.developerMode;
  $: currentJob = jobs.currentJob;
  $: autoSaveEnabled = workflow.autoSaveEnabled;
  $: selectedWorkflow = filetree.selectedWorkflow;

  const additionalHelp = persisted("show_additional_help", true);
  const toggleAdditionalHelp = () => additionalHelp.update((value) => !value);
  const toggleDebug = () => ($toolbar.show_debug = !$toolbar.show_debug);
  const onClickCode = () => ($toolbar.show_code = !$toolbar.show_code);
  const onClickTable = () => ($toolbar.show_table = !$toolbar.show_table);

  function togglePropPane() {
    $toolbar.show_prop_pane = !$toolbar.show_prop_pane;
  }

  function toggleCodePane() {
    $toolbar.show_code_pane = !$toolbar.show_code_pane;
  }

  let isHelpOpen = false;
  const toggleHelp = () => (isHelpOpen = !isHelpOpen);
  const keyboardShortcuts = {
    Navigation: {
      "Move Up": "↑",
      "Move Down": "↓",
      "Move Right": "→",
      "Move Left": "←",
      "Enter a control flow block": "Enter",
      "Zoom In": "+",
      "Zoom Out": "-",
      "Reset View": "F",
    },
    Operation: {
      Copy: ["ctrl_or_meta", "C"],
      Paste: ["ctrl_or_meta", "V"],
      Duplicate: ["ctrl_or_meta", "D"],
      Cut: ["ctrl_or_meta", "X"],
      "Toggle Skip": ["ctrl_or_meta", "/"],
      Delete: "Delete",
      Undo: ["ctrl_or_meta", "Z"],
      Redo: ["ctrl_or_meta", "Shift", "Z"],
    },
  };

  let apps = [];
  let devices = [];
  let selectedApp = null;
  let selectedDevice = null;

  async function onOpenDropdown() {
    const targets = await $liveStore.request("request_targets");

    apps = targets.apps
      .map((app) => ({
        value: app.id,
        label: app.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    devices = targets.devices
      .map((device) => ({
        value: device.id,
        label: `${device.make} ${device.model}`,
        image: device.logo,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    if (selectedApp == null && apps.length > 0) {
      selectedApp = apps[0].value;
    }

    if (selectedDevice == null && devices.length > 0) {
      selectedDevice = devices[0].value;
    }
  }

  function getCurrentWorkflow() {
    const currentWorkflow = get(workflow);
    if (!currentWorkflow || !currentWorkflow.sequence || currentWorkflow.sequence.length === 0) {
      console.warn("No workflow or empty sequence");
      return null;
    }
    return currentWorkflow;
  }

  function onSimulateWorkflow() {
    console.clear();
    const workflow = getCurrentWorkflow();
    if (workflow) {
      execution.clearAllStatuses();
      simulateWorkflow(generateEvents(workflow), (event) => execution.processEvent(event), 1000);
    }
  }

  function onAddExampleBlocks() {
    workflow.addBlock("open_app");
    workflow.addBlock("press_key");
    workflow.addBlock("close_app");
  }

  function onAddValidationErrors() {
    console.clear();
    execution.clearAllValidations();
    const workflow = getCurrentWorkflow();
    if (workflow) {
      simulateWorkflow(
        generateValidationIssues(workflow),
        (event) => execution.processEvent(event),
        10,
      );
    }
  }
</script>

<div class="flex items-center py-1 px-4 bg-white border-b border-gray-200">
  <div class="flex space-x-2 items-center absolute">
    {#if $selectedWorkflow}
      <Icon
        src={$selectedWorkflow.meta?.type === "library" ? Document : DocumentCheck}
        class="w-4 h-4"
      />
      <div class="text-sm font-semibold">{$selectedWorkflow.name}</div>
    {/if}

    {#if $toolbar.show_debug}
      <div class="text-xs font-mono">
        <strong>{$isValid ? "valid" : "invalid"}</strong> | <span>{$currentStep}</span>
      </div>
      <button
        class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8"
        on:click={onAddExampleBlocks}
        title="Add random blocks"
      >
        <Icon src={PlusCircle} class="w-5 h-5" />
      </button>
      <button
        class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8"
        on:click={onSimulateWorkflow}
        title="Simulate Workflow Run"
      >
        <Icon src={PlayCircle} class="w-5 h-5" />
      </button>
      <button
        class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8"
        on:click={onAddValidationErrors}
        title="Add random validation errors"
      >
        <Icon src={ExclamationCircle} class="w-5 h-5" />
      </button>
    {/if}
  </div>

  <div class="flex-grow" />

  <div class="flex space-x-4">
    <button
      class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8 disabled:opacity-20"
      class:bg-gray-300={$toolbar.show_code}
      on:click={onClickCode}
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle code view"
      disabled={!hasActiveWorkflow}
    >
      <Icon src={CodeBracket} class="w-5 h-5" />
    </button>

    <button
      class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8 disabled:opacity-20"
      class:bg-gray-300={$toolbar.show_table}
      on:click={onClickTable}
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle table view"
      disabled={!hasActiveWorkflow}
    >
      <Icon src={TableCells} class="w-5 h-5" />
    </button>

    <button
      class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8 disabled:opacity-20"
      on:click={toggleCodePane}
      disabled={$toolbar.show_code}
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle code pane"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 104 108" class="w-5 h-5">
        <rect
          x="0"
          y="0"
          width="50"
          height="100"
          fill={$toolbar.show_code_pane ? "black" : "none"}
          stroke="black"
          stroke-width="8"
        />
        <rect x="50" y="0" width="50" height="100" fill="none" stroke="black" stroke-width="8" />
      </svg>
    </button>

    <button
      class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8 disabled:opacity-20"
      on:click={togglePropPane}
      disabled={$toolbar.show_code}
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle properties pane"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 104 108" class="w-5 h-5">
        <rect
          x="50"
          y="0"
          width="50"
          height="100"
          fill={$toolbar.show_prop_pane ? "black" : "none"}
          stroke="black"
          stroke-width="8"
        />
        <rect x="0" y="0" width="50" height="100" fill="none" stroke="black" stroke-width="8" />
      </svg>
    </button>

    {#if $developerMode}
      <button
        class="p-1 text-black hover:bg-gray-300 rounded-md flex items-center justify-center w-8 h-8"
        on:click={toggleDebug}
        use:tooltip={{ placement: "bottom" }}
        aria-label="Toggle debug view"
      >
        <Icon src={BugAnt} class="w-5 h-5" />
      </button>
    {/if}

    <button
      on:click={toggleAdditionalHelp}
      class="p-1 text-black hover:bg-gray-300 rounded-md disabled:opacity-20"
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle Additional Help"
    >
      <Icon src={QueueList} class="w-5 h-5" />
    </button>

    <button
      on:click={toggleHelp}
      class="p-1 text-black hover:bg-gray-300 rounded-md disabled:opacity-20"
      use:tooltip={{ placement: "bottom" }}
      aria-label="Show Help"
    >
      <Icon src={QuestionMarkCircle} class="w-5 h-5" />
    </button>

    <button
      on:click={() => workflow.toggleAutoSaveEnabled()}
      class="p-1 text-black hover:bg-gray-300 rounded-md disabled:opacity-20"
      class:text-green-800={$autoSaveEnabled}
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle Auto-Save"
    >
      <Icon src={$autoSaveEnabled ? CheckCircle : PauseCircle} class="w-5 h-5" />
    </button>

    <button
      on:click={() => workflow.toggleReadonlyMode()}
      class="p-1 text-black hover:bg-gray-300 rounded-md disabled:opacity-20"
      class:text-green-800={$isReadonly}
      use:tooltip={{ placement: "bottom" }}
      aria-label="Toggle Readonly Mode"
    >
      <Icon src={$isReadonly ? LockClosed : LockOpen} class="w-5 h-5" />
    </button>
  </div>

  <div class="flex-grow" />

  <div class="flex items-center space-x-2 absolute right-4">
    {#if $currentJob}
      <div class="text-gray-400 text-xs">Job {$currentJob.id} {$currentJob.status}</div>
    {/if}
    <TraceDebug {apps} {devices} bind:selectedApp bind:selectedDevice on:open={onOpenDropdown} />
    <Execute {apps} {devices} on:open={onOpenDropdown} />
  </div>
</div>

<HelpModal bind:isOpen={isHelpOpen} maxWidth="32rem" {keyboardShortcuts} />
