// eslint-disable-next-line no-unused-vars
import uPlot from "uplot";

function setCursorToIndex(uplot, selectedIdx) {
  if (selectedIdx === null || selectedIdx === undefined || !uplot.data) {
    return;
  }

  const xPos = uplot.valToPos(uplot.data[0][selectedIdx], "x", false);
  const yPos = uplot.valToPos(uplot.data[1][selectedIdx], "y", false);

  uplot.setCursor({ left: xPos, top: yPos });
}

export function selected({ onSelectedIndex, selectedIndex: initialSelectedIndex, eventName }) {
  let onClickHandler = null;
  let selectedIdx = null;

  function init(uplot) {
    function onClick() {
      // should only fire when not dragging
      const timestamp = uplot.data[0][selectedIdx];
      onSelectedIndex && onSelectedIndex(selectedIdx, timestamp, eventName);
    }

    onClickHandler = uplot.over.addEventListener("click", onClick);
  }

  function destroy(uplot) {
    if (onClickHandler) {
      uplot.over.removeEventListener("click", onClickHandler);
    }
  }

  function setLegend(uplot) {
    selectedIdx = null;

    if (uplot.cursor.idx !== null && uplot.cursor.idx != selectedIdx) {
      selectedIdx = uplot.cursor.idx;
    }
  }

  return {
    hooks: {
      init,
      destroy,
      setLegend,
      draw(uplot) {
        setCursorToIndex(uplot, initialSelectedIndex);
      },
      setSelected(uplot, selected) {
        setCursorToIndex(uplot, selected);
      },
    },
  };
}
