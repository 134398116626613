import { detail } from "./detail";
import { samples } from "./samples";
import { whisker } from "./whisker";
import { histogram } from "./histogram";
import { dev } from "./dev";

export const presets = {
  detail,
  samples,
  whisker,
  histogram,
  dev,
};
