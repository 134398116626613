<script>
  import ColorInput from "$lib/Inputs/ColorInput.svelte";
  import NumericInput from "$lib/Inputs/NumericInput.svelte";
  import ColorAnalyzer from "$lib/Designer/ColorAnalzyer/ColorAnalyzer.svelte";
  import PropHelp from "../PropHelp.svelte";

  export let props;
  export let update;
  export let isReadonly = false;
  export let showAdditionalHelp = true;

  let tolerance = null;
  if (props.tolerance === undefined || props.tolerance === null) {
    if (props.lower === null && props.upper === null) {
      tolerance = 10;
    }
  } else {
    tolerance = Math.round(props.tolerance * 100);
  }

  let percentage = null;
  if (props.percentage === undefined) {
    percentage = 0;
  } else {
    percentage = Math.round(props.percentage * 100);
  }

  function handleColor(e) {
    for (const key in e.detail) {
      update({ detail: e.detail[key] }, key);
    }
  }

  function updatePercentage(e, key) {
    const value = e.detail === null ? null : e.detail / 100.0;
    update({ detail: value }, key);
  }
</script>

<!-- TODO: handle error validation, cannot specify both color and upper / lower -->

<div class={showAdditionalHelp ? "flex mt-2" : ""}>
  <PropHelp {showAdditionalHelp}>
    Color matcher can either
    <ul class="p-2">
      <li>- match a specified color with a tolerance value</li>
      <li>- match a range of colors explicitly specified by an upper and lower bound</li>
    </ul>
    You can only set one of these options.<br /><br />

    Note that in the first case, even a small percent of the area is in the specified color range,
    it will be considered a match.<br /><br />

    Tolerance is the percentage of color variation allowed. A value of 0 means the color must match
    exactly, and as the value increases, the tolerance for color variation increases in the HSV
    color space.
  </PropHelp>
</div>

<div class="flex flex-col mt-2">
  <ColorInput value={props.color} on:input={(e) => update(e, "color")} label="Color" {isReadonly} />
</div>

<div class="flex flex-col mt-2 relative">
  <NumericInput
    optional={true}
    value={tolerance}
    on:input={(e) => updatePercentage(e, "tolerance")}
    label="Tolerance (%)"
    variant="wide"
    {isReadonly}
  />
</div>

<div class="flex flex-col mt-2 relative">
  <NumericInput
    optional={true}
    value={percentage}
    on:input={(e) => updatePercentage(e, "percentage")}
    label="Coverage (%)"
    variant="wide"
    {isReadonly}
  />
  <PropHelp {showAdditionalHelp}>
    The total percentage of all the sub-areas that match the color compared to the total area of the
    region.
  </PropHelp>
</div>

<div class="flex mt-2">
  <ColorInput
    optional={true}
    value={props.upper}
    on:input={(e) => update(e, "upper")}
    label="Upper"
    {isReadonly}
  />
</div>

<div class="flex flex-col mt-2">
  <ColorInput
    optional={true}
    value={props.lower}
    on:input={(e) => update(e, "lower")}
    label="Lower"
    {isReadonly}
  />
</div>

<ColorAnalyzer on:color={handleColor} {props} />
