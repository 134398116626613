import uPlot from "uplot";
import { selected } from "../plugins/selected";

function drawPoints(u, seriesIdx, _idx0, _idx1) {
  const size = 6 * uPlot.pxRatio;

  uPlot.orient(
    u,
    seriesIdx,
    (
      series,
      _dataX,
      _dataY,
      scaleX,
      scaleY,
      valToPosX,
      valToPosY,
      xOff,
      yOff,
      xDim,
      yDim,
      _moveTo,
      _lineTo,
      _rect,
      arc,
    ) => {
      let d = u.data;
      let p = new Path2D();
      let deg360 = 2 * Math.PI;

      for (let i = 0; i < d[0].length; i++) {
        let xVal = d[0][i];
        let yVal = d[1][i];
        u.ctx.fillStyle = series.color(xVal, yVal);

        if (xVal >= scaleX.min && xVal <= scaleX.max && yVal >= scaleY.min && yVal <= scaleY.max) {
          let cx = valToPosX(xVal, scaleX, xDim, xOff);
          let cy = valToPosY(yVal, scaleY, yDim, yOff);

          p.moveTo(cx + size / 2, cy);
          arc(p, cx, cy, size / 2, 0, deg360);
        }
      }

      u.ctx.fill(p);
    },
  );

  return null;
}

export function samples({ labels, data, onSelectedIndex, selectedIndex }) {
  const r_min = Math.min(...data[0]) - 0.5;
  const r_max = Math.max(...data[0]) + 0.5;

  return {
    mode: 2,
    plugins: [selected({ onSelectedIndex, selectedIndex, eventName: "sample-selected" })],
    scales: {
      x: {
        time: false,
        range: [r_min, r_max],
      },
    },
    series: [
      {
        label: labels.m,
      },
      {
        label: "Individual measurement",
        width: 3,
        stroke: "red",
        color: (_x, _y) => "#9d174d",
        paths: drawPoints,
      },
    ],
    legend: {
      show: false,
    },
    axes: [{}, { size: 60 }],
  };
}
