import { writable, derived, get } from "$utils/shim";
import { createRegionStore } from "./regions";
import { createAssetStore } from "./assets";
import { generateUUID } from "$utils/uuid";

export function createScreenStores(liveStore) {
  const selectedScreenId = writable(null);
  const selectedElements = writable([]);
  const { subscribe, set, update } = writable({});

  const defaultBeforeReady = createAssetStore(liveStore);
  const currentAssets = derived(
    [{ subscribe, set, update }, selectedScreenId],
    ([$screens, $selectedScreenId]) => {
      return $screens[$selectedScreenId]?.assets || defaultBeforeReady;
    },
  );

  const selectedScreenRegions = derived(
    [{ subscribe }, selectedScreenId],
    ([$screenStores, $selectedScreenId]) => {
      const regionStore = $screenStores[$selectedScreenId];
      return (
        regionStore || {
          matchers: createRegionStore("-1", []),
        }
      );
    },
  );

  const activeRegions = derived(
    selectedScreenRegions,
    ($selectedScreenRegions, set) => {
      if ($selectedScreenRegions && $selectedScreenRegions.matchers) {
        if (typeof $selectedScreenRegions.matchers.subscribe === "function") {
          const unsubscribe = $selectedScreenRegions.matchers.subscribe((matchersValue) => {
            set(Array.isArray(matchersValue) ? matchersValue : []);
          });
          return unsubscribe;
        }
      }
      set([]);
    },
    [],
  );

  const screenStoresAsList = derived([{ subscribe }], ([$screenStores]) => {
    return Object.values($screenStores).sort((a, b) => a.order - b.order);
  });

  return {
    subscribe,

    selectedScreenId,

    selectedElements,

    selectedScreenRegions,

    screenStoresAsList,

    activeRegions,

    currentAssets,

    initialize(parsedData) {
      update(() => {
        return parsedData.reduce((stores, screen, index) => {
          stores[screen._id] = {
            _id: screen._id,
            name: screen.name,
            order: index,
            matchers: createRegionStore(screen._id, screen.matchers),
            assets: createAssetStore(liveStore),
          };
          if (screen.assets) {
            stores[screen._id].assets.initialize(screen.assets);
          }
          return stores;
        }, {});
      });
    },

    addScreen(screen) {
      const id = generateUUID();
      update((stores) => {
        const maxOrder = Math.max(...Object.values(stores).map((s) => s.order), -1);
        screen = {
          _id: id,
          order: maxOrder + 1,
          matchers: createRegionStore(id, screen.matchers || []),
          assets: createAssetStore(liveStore),
          ...screen,
        };
        stores[screen._id] = screen;
        return stores;
      });

      // unselect regions and select new screeen
      selectedElements.set([]);
      selectedScreenId.set(id);
      return id;
    },

    deleteScreen(screenId) {
      update((stores) => {
        if (stores[screenId] && stores[screenId].matchers) {
          stores[screenId].matchers.reset();
        }

        if (stores[screenId] && stores[screenId].assets) {
          stores[screenId].assets.reset();
        }

        if (get(selectedScreenId) === screenId) {
          selectedScreenId.set(null);
          selectedElements.set([]);
        }

        delete stores[screenId];
        return stores;
      });
    },

    updateName(screenId, value) {
      update((stores) => {
        if (stores[screenId]) {
          stores[screenId].name = value;
        }
        return stores;
      });
    },

    updateRegionName(screenId, regionId, value) {
      update((stores) => {
        if (stores[screenId]) {
          stores[screenId].matchers.updateProp(regionId, "name", value);
        }
        return stores;
      });
    },

    uploadAssets(screenId, files) {
      update((stores) => {
        if (stores[screenId]) {
          stores[screenId].assets.upload(files);
        }
        return stores;
      });
    },

    removeAsset(screenId, assetIndex) {
      update((stores) => {
        if (stores[screenId]) {
          stores[screenId].assets.remove(assetIndex);
        }
        return stores;
      });
    },

    moveScreen(screenId, direction) {
      update((stores) => {
        const screensList = Object.values(stores).sort((a, b) => a.order - b.order);
        const currentIndex = screensList.findIndex((s) => s._id === screenId);

        if (currentIndex === -1) return stores;

        let newIndex;
        switch (direction) {
          case "up":
            newIndex = currentIndex - 1;
            break;
          case "down":
            newIndex = currentIndex + 1;
            break;
          case "top":
            newIndex = 0;
            break;
          case "bottom":
            newIndex = screensList.length - 1;
            break;
          default:
            return;
        }

        if (newIndex < 0 || newIndex >= screensList.length) return stores;
        if (direction === "up" || direction === "down") {
          const temp = screensList[currentIndex].order;
          screensList[currentIndex].order = screensList[newIndex].order;
          screensList[newIndex].order = temp;
        } else {
          const from = direction === "top" ? 0 : currentIndex + 1;
          const to = direction === "top" ? currentIndex - 1 : screensList.length - 1;
          const incr = direction === "top" ? 1 : -1;
          for (let i = from; i <= to; i++) {
            screensList[i].order += incr;
          }
          screensList[currentIndex].order = direction === "top" ? 0 : screensList.length - 1;
        }

        return screensList.reduce((acc, screen) => {
          acc[screen._id] = screen;
          return acc;
        }, {});
      });
    },

    deleteSelected() {
      const selectedElementIds = get(selectedElements);

      if (selectedElementIds.length > 0) {
        update((stores) => {
          const screen = stores[get(selectedScreenId)];
          if (!screen) return stores;
          screen.matchers.deleteMany(selectedElementIds);
          selectedElements.set([]);
          return stores;
        });
      }
    },

    changeSelection(list) {
      update((stores) => {
        const screen = stores[get(selectedScreenId)];

        if (!screen) return stores;

        if (list === null) {
          selectedElements.set([]);
        } else {
          selectedElements.set(list);
        }
        return stores;
      });
    },

    selectScreen(screenId) {
      selectedScreenId.set(screenId);
    },

    reset() {
      const stores = get({ subscribe });

      Object.values(stores).forEach((screen) => {
        if (screen.matchers) {
          screen.matchers.reset();
        }
        if (screen.assets) {
          screen.assets.reset();
        }
      });

      set({});
      selectedElements.set([]);
      selectedScreenId.set(null);
      defaultBeforeReady.reset();
    },
  };
}
