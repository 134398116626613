<script>
  import {
    Icon,
    ArrowsPointingIn,
    ArrowsPointingOut,
    FolderPlus,
    DocumentPlus,
    Trash,
    ArrowRightCircle,
    DocumentDuplicate,
    BookOpen,
    ArrowDownTray,
  } from "svelte-hero-icons";
  import { tooltip } from "$utils/tooltip";
  import { filetree, workflow, projectName } from "../store";
  import { get } from "$lib/utils/shim";

  $: selectedNode = filetree.selectedNode;
  $: isMoving = filetree.isMoving;
  $: isCopying = filetree.isCopying;

  function handleDelete() {
    if (!$selectedNode) return;

    const message = `Are you sure you want to delete this ${$selectedNode.is_folder ? "folder" : "workflow"}?`;

    if (confirm(message)) {
      filetree.deleteItem($selectedNode.id);
    }
  }

  function handleToggleType() {
    if (!$selectedNode) return;

    filetree.toggleType($selectedNode.id);
  }

  function downloadWorkflow() {
    if (!$selectedNode) return;
    const workflowJson = JSON.stringify(get(workflow), null, 2);
    const project_name = get(projectName);
    const filename = (project_name ? project_name + " - " : "") + $selectedNode.name || "workflow";
    const blob = new Blob([workflowJson], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename}.json`;
    a.click();
    URL.revokeObjectURL(url);
  }
</script>

<div class="flex space-x-1 bg-gray-100 p-1 rounded-md">
  <button
    use:tooltip
    aria-label="Expand All"
    on:click={() => filetree.expandAll()}
    class="p-1 hover:bg-gray-200 rounded disabled:opacity-50"
  >
    <Icon src={ArrowsPointingOut} class="w-4 h-4" />
  </button>
  <button
    use:tooltip
    aria-label="Collapse All"
    on:click={() => filetree.collapseAll()}
    class="p-1 hover:bg-gray-200 rounded disabled:opacity-50"
  >
    <Icon src={ArrowsPointingIn} class="w-4 h-4" />
  </button>
  <div class="w-px bg-gray-300 mx-1"></div>
  <button
    use:tooltip
    aria-label="Add Folder"
    on:click={() => filetree.addNewItem("folder", $selectedNode?.id)}
    class="p-1 hover:bg-gray-200 rounded disabled:opacity-50"
    disabled={$selectedNode?.is_folder === false}
  >
    <Icon src={FolderPlus} class="w-4 h-4" />
  </button>
  <button
    use:tooltip
    aria-label="Add Workflow"
    on:click={() => filetree.addNewItem("file", $selectedNode?.id)}
    class="p-1 hover:bg-gray-200 rounded disabled:opacity-50"
    disabled={$selectedNode?.is_folder === false}
  >
    <Icon src={DocumentPlus} class="w-4 h-4" />
  </button>
  <div class="w-px bg-gray-300 mx-1"></div>
  <button
    use:tooltip
    aria-label="Delete test case / folder"
    on:click={handleDelete}
    class="p-1 hover:bg-gray-200 rounded disabled:opacity-50"
    disabled={!$selectedNode}
  >
    <Icon src={Trash} class="w-4 h-4" />
  </button>
  <div class="w-px bg-gray-300 mx-1"></div>
  <button
    use:tooltip
    aria-label="Move Node"
    on:click={() => filetree.startMove()}
    class="p-1 hover:bg-gray-200 rounded disabled:opacity-50"
    disabled={!$selectedNode}
  >
    <Icon src={ArrowRightCircle} class="w-4 h-4" />
  </button>
  <button
    use:tooltip
    aria-label="Copy Node"
    on:click={() => filetree.startCopy()}
    class="p-1 hover:bg-gray-200 rounded disabled:opacity-50"
    disabled={!$selectedNode}
  >
    <Icon src={DocumentDuplicate} class="w-4 h-4" />
  </button>
  <button
    use:tooltip
    aria-label="Change to library/folder"
    on:click={handleToggleType}
    class="p-1 hover:bg-gray-200 rounded disabled:opacity-50"
    disabled={!$selectedNode}
  >
    <Icon src={BookOpen} class="w-4 h-4" />
  </button>
  <button
    use:tooltip
    aria-label="Download Workflow"
    on:click={downloadWorkflow}
    class="p-1 hover:bg-gray-200 rounded disabled:opacity-50"
    disabled={!$selectedNode || $selectedNode.is_folder}
  >
    <Icon src={ArrowDownTray} class="w-4 h-4" />
  </button>
</div>

{#if $isMoving || $isCopying}
  <div class="px-2 py-1 mt-2 bg-yellow-100 text-xs rounded flex items-center">
    {$isMoving ? "Moving node." : "Copying node."} Select a destination folder.
    <button
      on:click={() => filetree.cancelMoveOrCopy()}
      class="ml-1 text-red-600 hover:text-red-800"
    >
      Cancel
    </button>
  </div>
{/if}
