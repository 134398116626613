<script>
  import { createEventDispatcher } from "svelte";
  import { clickOutside } from "$utils/clickOutside";
  import { Icon, ChevronDown, XMark } from "svelte-hero-icons";

  export let value = 0;
  export let label;
  export let variant = "default";
  export let optional = false;
  export let signed = true;
  export let isReadonly = false;
  export let defaultUnit = "milliseconds";

  const id = String(Math.random());
  const dispatch = createEventDispatcher();
  const unitMap = {
    milliseconds: "ms",
    seconds: "sec",
    minutes: "min",
    hours: "hr",
  };

  let unit = defaultUnit;
  let isOpen = false;
  let fakeValue = value;

  $: {
    if (value !== null) {
      fakeValue = convertValue(value, "milliseconds", unit);
    } else {
      fakeValue = "";
    }
  }

  function convertValue(value, fromUnit, toUnit) {
    if (value === null) return null;
    if (fromUnit === toUnit) return value;

    // Convert to milliseconds first
    let inMilliseconds;
    switch (fromUnit) {
      case "hours":
        inMilliseconds = value * 3600000;
        break;
      case "minutes":
        inMilliseconds = value * 60000;
        break;
      case "seconds":
        inMilliseconds = value * 1000;
        break;
      default:
        inMilliseconds = value;
    }

    // Convert from milliseconds to target unit
    switch (toUnit) {
      case "hours":
        return Math.round(inMilliseconds / 3600000);
      case "minutes":
        return Math.round(inMilliseconds / 60000);
      case "seconds":
        return Math.round(inMilliseconds / 1000);
      default:
        return inMilliseconds;
    }
  }

  function handleInput(event) {
    if (isReadonly) return;

    const inputValue = event.target.value;
    fakeValue = inputValue !== "" ? parseInt(inputValue, 10) : null;
    value = convertValue(fakeValue, unit, "milliseconds");
    dispatch("input", value);
  }

  function handleUnitChange(newUnit) {
    if (isReadonly || unit === newUnit) return;

    isOpen = false;
    fakeValue = convertValue(fakeValue, unit, newUnit);
    value = convertValue(fakeValue, newUnit, "milliseconds");
    unit = newUnit;
    dispatch("input", value);
  }

  function clearValue() {
    if (isReadonly) return;

    fakeValue = "";
    value = null;
    dispatch("input", null);
  }
</script>

<div class="flex flex-col basis-full" class:opacity-60={isReadonly}>
  <div
    aria-label="Select"
    class="w-full flex items-center {$$props.class || ''}"
    use:clickOutside
    on:clickoutside={() => (isOpen = false)}
  >
    <div class="relative w-full">
      <input
        type="number"
        class="fake flex items-center w-full h-8 text-left rounded p-2 pr-8 text-xs bg-gray-100 border-0 focus:outline-none
          {isReadonly ? 'cursor-not-allowed' : 'focus:ring-2 focus:ring-blue-500'}"
        class:pl-8={variant == "narrow"}
        class:pl-12={variant == "default"}
        class:pl-32={variant == "wide"}
        class:pl-40={variant == "ultrawide"}
        bind:value={fakeValue}
        min={signed ? null : 0}
        on:input={handleInput}
        readonly={isReadonly}
      />
      <input
        {id}
        type="number"
        class="real hidden"
        value={value ?? ""}
        min={signed ? null : 0}
        readonly={isReadonly}
      />

      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label
        class="absolute left-2 top-1/2 -translate-y-1/2 text-xs text-gray-500 pointer-events-none transition-all"
        class:cursor-not-allowed={isReadonly}
      >
        {label} ({unitMap[unit]})
      </label>

      {#if !isReadonly}
        <button
          class="absolute right-2 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-500 focus:outline-none"
          on:click={() => (isOpen = !isOpen)}
        >
          <Icon src={ChevronDown} class="w-4 h-4" />
        </button>
      {/if}
    </div>

    {#if optional && !isReadonly}
      <button
        type="button"
        class="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
        on:click={clearValue}
      >
        <Icon src={XMark} class="w-4 h-4" />
      </button>
    {/if}

    <slot name="dynamic" />
  </div>

  <div
    class="z-50 mt-1 py-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-40 overflow-auto top-full"
    class:hidden={!isOpen || isReadonly}
    class:mr-6={optional}
  >
    {#each Object.keys(unitMap) as unitOption}
      <button
        type="button"
        class="flex items-center w-full px-2 py-1 text-left text-xs hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
        on:click={() => handleUnitChange(unitOption)}
      >
        <span class="truncate">{unitOption}</span>
      </button>
    {/each}
  </div>
</div>
