<script>
  import "sequential-workflow-designer/css/designer.css";
  import "sequential-workflow-designer/css/designer-light.css";
  import "sequential-workflow-designer/css/designer-dark.css";

  import { onMount, onDestroy } from "svelte";
  import {
    toolbar,
    workflow as workflowStore,
    initializeStore,
    luaCode,
    execution,
    definitions,
    filetree,
    jobs,
  } from "./Automator/store";
  import { Icon, LightBulb, Sparkles, ExclamationCircle } from "svelte-hero-icons";
  import MenubarWorkflow from "./Automator/MenubarWorkflow.svelte";
  import SidebarWorkflow from "./Automator/SidebarWorkflow.svelte";
  import SequentialWorkflowDesigner from "./Automator/SequentialWorkflowDesigner.svelte";
  import CodeInput from "./Inputs/CodeInput.svelte";
  import { cx } from "$utils/cx";
  import { processEventBatch } from "$utils/events";
  import { nonReactiveVariable } from "./utils/svelte";
  import StatusBanner from "./Automator/Workflow/StatusBanner.svelte";
  import PropTable from "./Automator/PropTable.svelte";

  export let code;
  export let job;
  export let workflow;
  export let active_workflow_id;
  export let ui_assets;
  export let toolbox;
  export let root_definition;
  export let project_id;
  export let project_name;
  export let live;
  export let hidden = false;
  export let is_readonly = false;
  export let mock_env = false;
  export let tracing = false;
  export let debugging = false;
  export let user_roles = ["user"];
  export let user_nickname;
  export let tree_items = [];

  // Non-reactive value to track if the component is mounted
  const isMounted = nonReactiveVariable(false);

  // TODO: Event driven vs server driven
  $: execution.mode.set(tracing || debugging ? "in_progress" : "idle");
  $: luaCode.set(code);
  $: {
    definitions.reset();
    definitions.initialize(toolbox, root_definition, user_roles);
  }

  $: if (isMounted.value) jobs.selectJob(job);
  $: if (isMounted.value) filetree.initialize(tree_items);
  $: if (isMounted.value) workflowStore.update(active_workflow_id, workflow);
  $: isUserReadonly = workflowStore.isUserReadonly;

  const isDirty = workflowStore.isDirty;
  const hasConnectionIssue = workflowStore.hasConnectionIssue;
  const autoSaveEnabled = workflowStore.autoSaveEnabled;

  function handleWorkflowEvents(params) {
    if (!params) return null;
    const { events, isRecording } = params;
    if (isRecording) {
      execution.clearBeforeExecution();
    }
    processEventBatch(events, execution.processEvent);
  }

  function handleWorkflowFailedToStart(params) {
    if (!params) return null;
    const { reason } = params;
    execution.workflowFailedToStart(reason);
  }

  onMount(() => {
    initializeStore(live, {
      project_id,
      toolbox,
      root_definition,
      workflow,
      ui_assets,
      user_roles,
      user_nickname,
      is_readonly,
      job,
      tree_items,
      project_name,
    });
    live.handleEvent("workflow_events", handleWorkflowEvents);
    live.handleEvent("workflow_failed_to_start", handleWorkflowFailedToStart);
    isMounted.set(true);
  });

  onDestroy(() => {
    live.removeHandleEvent(handleWorkflowEvents);
    live.removeHandleEvent(handleWorkflowFailedToStart);
  });
</script>

{#if !hidden}
  <MenubarWorkflow hasActiveWorkflow={!!workflow} />

  <div
    class={cx("relative flex overflow-hidden automator-height-container", {
      "automator-mock-env": mock_env,
    })}
    id="workflow-designer-wrapper"
  >
    {#if $toolbar.show_code_pane}
      <SidebarWorkflow />
    {/if}
    <div class="relative w-full">
      {#if workflow}
        {#if $toolbar.show_code}
          <div class="absolute top-0 left-0 w-full h-full">
            <CodeInput
              value={code}
              language="lua"
              isReadonly
              isSynced
              variant="standalone"
              theme="light"
            />
          </div>
        {:else if $toolbar.show_table}
          <PropTable />
        {:else}
          <!-- This fixed a bug where SequentialWorkflowDesigner doesn't work when not visible -->
          <SequentialWorkflowDesigner />

          {#if $isDirty}
            {#if $autoSaveEnabled}
              <div class="absolute top-3 left-1/2 -translate-x-1/2 pr-80">
                <StatusBanner
                  variant={$hasConnectionIssue ? "warn" : "info"}
                  icon={$hasConnectionIssue ? ExclamationCircle : Sparkles}
                  message={$hasConnectionIssue
                    ? "Connection issue, trying to save..."
                    : "Saving changes..."}
                />
              </div>
            {:else}
              <div class="absolute top-3 left-1/2 -translate-x-1/2 pr-80">
                <button
                  on:click={() => workflowStore.saveWorkflow()}
                  class="text-white font-bold py-1 px-8 rounded border border-red-800 bg-red-500 hover:bg-red-400 shadow-lg"
                >
                  {$hasConnectionIssue ? "Connection issue, trying again." : "Save"}
                </button>
              </div>
            {/if}
          {/if}

          {#if $isUserReadonly}
            <div class="absolute top-3 left-1/2 -translate-x-1/2 pr-20">
              <StatusBanner
                variant="warn"
                icon={ExclamationCircle}
                message="Read-only. Click lock to start editing."
              />
            </div>
          {/if}
        {/if}
      {:else}
        <div class="w-full h-full flex flex-col items-center justify-center">
          <p class="text-xl font-semibold text-slate-400 mb-4">No workflow selected</p>
          <Icon src={LightBulb} class="mb-4 w-32 h-32 text-slate-400" />
          <p class="text-sm text-slate-400">
            To get started, select a workflow from the left sidebar or create a new one.
          </p>
        </div>
      {/if}
    </div>
  </div>
{/if}
