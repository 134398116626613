export const BulkDownload = {
  mounted() {
    this.handleEvent("bulk_download", async ({ links }) => {
      for (const { link, name } of links) {
        console.log("Downloading ", link);

        try {
          const response = await fetch(link);

          if (!response.ok) {
            throw new Error(`Failed to download: ${response.statusText}`);
          }

          // Convert response to a Blob
          const blob = await response.blob();

          // Create an object URL for the Blob
          const objectUrl = URL.createObjectURL(blob);

          // Create a temporary anchor tag and trigger download
          const a = document.createElement("a");
          a.href = objectUrl;

          // Extract filename from URL or use a default name
          a.download = name;

          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          // Revoke the object URL to free up memory
          URL.revokeObjectURL(objectUrl);
        } catch (error) {
          console.error("Error downloading file:", error.message);
        }
      }
    });
  },
};
